<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室基准项目比价系数</h2>
                <el-table :data="DeplisttableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="科室名称" width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="updateDate" label="更新时间" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editJixiao(scope.row)"><i class="el-icon-s-operation" style="font-size: 15px"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击操作科室基准项目系数 -->
        <el-dialog title="科室基准项目比价系数" :visible.sync="depBenchmarkdialogVisible" width="90%" top="1vh" :before-close="depBenchmarkdialogVisibleClose">
            <measInside :keshiId='keshiId' />
        </el-dialog>
    </el-container>
</template>

<script>
import measInside from "./MeasurementInside.vue";
export default {
    data() {
        return {
            //科室基准项目系数弹出框
            depBenchmarkdialogVisible: false,
            // 表格数据
            DeplisttableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 总页数
            total: 1,
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 科室列表的id
            keshiId: "",
        };
    },
    components: {
        measInside,
    },
    created() {
        this.getDepartmentNotPage();
    },
    methods: {
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getDepartmentNotPage();
        },
        //   获取医院所属科室列表
        async getDepartmentNotPage() {
            let data = {
                hospitalId: this.hospitalId,
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示数量
            };
            let { data: res } = await this.$axios.getDepartmentList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取医院所属科室列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.DeplisttableData = res.rows;
                this.total = res.total;
            }
        },
        editJixiao(row) {
            this.keshiId = row.id;
            this.depBenchmarkdialogVisible = true;
        },
        depBenchmarkdialogVisibleClose() {
            this.depBenchmarkdialogVisible = false;
            this.depBenchmarkpageNum = 1;
        },
    },
};
</script>

<style lang="less" scoped>
.activeStatus {
    background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

.noStatus {
    background: #ccc;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
}

.depar-dia {
    width: 100%;
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .oneBack {
    background: #c4dfec;
}
</style>
